import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Brasil from './Brasil';
import Map from './Map';
import Input from '../../components/Input';
import Button from '../../components/Button';
import StoreData from './StoreData';
import endpoints from '../../content/endpoints';

import './style.sass';

const selectedStores = (selectedState) => (loja) => (
  loja.estado.toUpperCase() === selectedState.toUpperCase()
);

const selectedCities = (selectedState) => (cidade) => (
  cidade.estado.toUpperCase() === selectedState.toUpperCase()
);



const loadRemoteData = (setData, url) => {
  
  fetch(url)
    .then(res => res.json())
    .then(data => {
      setData(data);
    })
    .catch(err => {})
  /*"Caso não funcione acima use o code comentado"
    
  if(url === endpoints.cidades){
    fetch(url)
      .then(res => res.json())
      .then(cidadesData => {
        setData(cidadesData);
      })
      .catch(err => {})
  }
  fetch(url)
    .then(res => res.json())
    .then(lojasData => {
      setData(lojasData);
    })
    .catch(err => {})
  */
  
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Stores = () => {
  const query = useQuery();

  const [lojas, setLojas] = useState([]);
  const [cidades, setCidades] = useState([]);

  const [stores, setStores] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedStore, setSelectedStore] = useState({});
  const [selectedCity, setSelectedCity] = useState('0');
  const [selectedState, setSelectedState] = useState(query.get('estado') || '');

  const [hasFribal,] = useState(true);
  const [hasEmporio,] = useState(true);

  useEffect(() => {
    loadRemoteData(setLojas, endpoints.lojas);
    loadRemoteData(setCidades, endpoints.cidades);
  }, []);

  useEffect(() => {
    const storeList = lojas
      .filter(selectedStores(selectedState))
      .filter((store) => (!store.tipo ? hasFribal : true))
      .filter((store) => ((store.tipo === 'emporio') ? hasEmporio : true))
      .map((store) => ({
        ...store,
        selectStore: () => setSelectedStore(store),
      }));

    setStores(storeList);
    setCities(cidades.filter(selectedCities(selectedState)));
  }, [selectedState, hasEmporio, hasFribal, lojas, cidades]);

  return (
    <main className="Stores">
      <Helmet>
        <title>Lojas | FRIBAL - Especialistas em Carne</title>
      </Helmet>
      <div className={`Stores__sidebar ${selectedState ? 'Stores__sidebar--active' : ''}`}>
        <h2 className="Stores__title">Lojas</h2>


        <div className="Stores__filters">
          <Button onClick={() => { setSelectedState(''); setSelectedStore({}); }} theme="primary-outline Stores__button">Escolher outro estado</Button>
          <span className="Stores__label">Lugar</span>
          <Input.Select
            name="state"
            label="Cidade"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            {
              cities.map((city, i) => (
                <option key={city.cidade.toLowerCase()} value={i}>
                  {`${city.cidade} - ${city.estado}`}
                </option>
              ))
            }
          </Input.Select>
        </div>
        {selectedState.toLowerCase() === 'ce' && Object.entries(selectedStore).length === 0 ? (
          <p className="Stores__description">Com presença marcante no estado do Ceará com mais de 120 lojas, em um exclusivo modelo de parceria com as principais redes de supermercados do estado, onde respondemos por todo o setor de carnes, garantindo o produto e o atendimento com a qualidade FRIBAL.</p>
        ) : null}
        {selectedStore.nome ? (
          <div className="Stores__content">
            <span className="Stores__label">Loja</span>
            <StoreData store={selectedStore} removeStore={() => setSelectedStore({})}/>
          </div>
        ) : null}
      </div>
      <div className="Stores__brasil">
        <h3 className="Stores__subtitle">
          { !selectedState ? 'Escolha um dos estados abaixo' : 'Navegue pelas lojas' }
        </h3>
        {
          !selectedState
            ? <Brasil setState={(state) => { setSelectedState(state); setSelectedCity('0'); }} />
            : (
              <Map
                stores={stores}
                selectedStore={selectedStore}
                city={cities[parseInt(selectedCity, 10)]}
              />
            )
        }
      </div>
    </main>
  );
};

export default Stores;
