/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel']),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
};

const defaultProps = {
  label: '',
  type: 'text',
  error: null,
  value: '',
};

const Input = ({
  name, type, label, onChange, value, error,
}) => {
  const [classNames, setClassNames] = useState('Input');

  useEffect(() => {
    setClassNames(value.length <= 0 ? 'Input' : 'Input Input--active');
  }, [value]);

  return (
    <div className={classNames}>
      <label htmlFor={name} className="Input__label">
        {label}
      </label>

      <input
        type={type}
        name={name}
        className="Input__field"
        onChange={onChange}
        value={value}
      />

      {error ? <span className="Input__error">{error}</span> : null}
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

const Select = ({
  name, label, onChange, value, children, error,
}) => {
  const [classNames, setClassNames] = useState('Input Input--select');

  useEffect(() => {
    if (label) {
      setClassNames(value.length <= 0 ? 'Input Input--select' : 'Input Input--select Input--active');
    }
  }, [value, label]);

  return (
    <div className={classNames}>
      <label htmlFor={name} className="Input__label">
        {label}
      </label>

      <select
        name={name}
        className="Input__field"
        onChange={onChange}
        value={value}
      >
        <option value="" disabled />
        {children}
      </select>

      {error ? <span className="Input__error">{error}</span> : null}
    </div>
  );
};

Select.propTypes = {
  ...propTypes,
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  ).isRequired,
};

Select.defaultProps = {
  ...defaultProps,
};

Input.Select = Select;

const Area = ({
  name, label, onChange, value, error,
}) => {
  const [classNames, setClassNames] = useState('Input');

  useEffect(() => {
    setClassNames(value.length <= 0 ? 'Input Input--area' : 'Input Input--area Input--active');
  }, [value]);

  return (
    <div className={classNames}>
      <label htmlFor={name} className="Input__label">
        {label}
      </label>

      <textarea
        name={name}
        className="Input__field"
        onChange={onChange}
        value={value}
      />

      {error ? <span className="Input__error">{error}</span> : null}
    </div>
  );
};

Area.propTypes = {
  ...propTypes,
};

Area.defaultProps = {
  ...defaultProps,
};

Input.Area = Area;

export default Input;
