import React from 'react';
import { Helmet } from 'react-helmet';
import Form from './Form';

import './style.sass';


const Contact = () => (
  <main className="Contact">
    <Helmet>
      <title>Fale conosco | FRIBAL - Especialistas em Carne</title>
    </Helmet>
    <section className="Contact__content">
      <h2 className="Contact__title">Fale conosco</h2>
      <div className="Contact__container">
        <section className="Contact__form-container">
          <h3 className="Contact__subtitle">Envie uma mensagem</h3>
          <p>
            Para falar com a nossa equipe, preencha atentamente o formulário.
          </p>

          <Form />
        </section>
        <aside className="Contact__aside Contact__aside--atendimento">
          <h3 className="Contact__subtitle Contact__subtitle--atendimento">Escritório Central Maranhão</h3>
          <a href="tel:+559832165775" className="Contact__phone"><span>98</span> 3216-5775</a>
          <p>Segunda a sexta-feira: das 07h30 às 18h Sábado: das 08h às 12h</p>
          <br />
          <h3 className="Contact__subtitle Contact__subtitle--atendimento">Escritório Central Ceará</h3>
          <a href="tel:+559832114500" className="Contact__phone"><span>85</span> 3211-4500</a>
          <p>Segunda a sexta-feira: das 07h30 às 18h Sábado: das 08h às 12h</p>
        </aside>
      </div>
    </section>

  </main>
);

export default Contact;
