import React from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable global-require */

export default {
  pecuaria: {
    meta: {
      prev: null,
      next: '/industria',
      title: 'Pecuária',
      position: '01',
      360: null,
      fotos: [
        { id: 1, src: require('../assets/pecuaria/foto1.jpeg'), description: " " },
        { id: 2, src: require('../assets/pecuaria/foto2.jpeg'), description: " " },
        { id: 3, src: require('../assets/pecuaria/foto3.jpeg'), description: " " },
        { id: 4, src: require('../assets/pecuaria/foto4.jpeg'), description: " " },
        { id: 5, src: require('../assets/pecuaria/foto5.jpeg'), description: " " },
        { id: 6, src: require('../assets/pecuaria/foto6.jpeg'), description: " " },
        { id: 7, src: require('../assets/pecuaria/foto7.jpeg'), description: " " },
        { id: 8, src: require('../assets/pecuaria/foto8.jpeg'), description: " " },
        { id: 9, src: require('../assets/pecuaria/foto9.jpeg'), description: " " },
        { id: 10, src: require('../assets/pecuaria/foto10.jpeg'), description: " " },
      ]
    },
    home: {
      link: "/pecuaria",
      title: "Pecuária",
      about: "Garantia de Origem & Responsabilidade no Manejo",
      background: {
        0: require('../assets/pecuaria/pecuaria-banner.jpg'),
        566: require('../assets/pecuaria/pecuaria-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/pecuaria/pecuaria-subheader.jpg'),
      title: 'Garantia de origem e manejo responsável',
      description: 'Garantia, Confiança e Sustentabilidade são tão fundamentais para a FRIBAL, quanto produtividade, eficiência e lucratividade, por isso, focamos na melhoria contínua das nossas práticas e processos, buscando ir além dos atuais requisitos do mercado quando se trata da origem de manejo do nosso rebanho.',
    },
    sections: [
      {
        id: 1,
        image1: require('../assets/pecuaria/pecuaria-1.jpg'),
        image2: require('../assets/pecuaria/pecuaria-2.jpg'),
        title: 'Garantia de origem',
        descriptions: [
        'Nosso rebanho é originado a partir dos melhores criadores de gado nelore dos estados do Maranhão e do Pará, animais criteriosamente selecionados por compradores profissionais; especialistas com a capacidade de projetar os aspectos zootécnicos de um bezerro, em um boi gordo, com acabamento dentro das expectativas de qualidade da FRIBAL.',
        ],
      },
      {
        id: 2,
        image1: require('../assets/pecuaria/pecuaria-3.jpg'),
        image2: require('../assets/pecuaria/pecuaria-4.jpg'),
        title: 'Bem estar animal',
        descriptions: [
        'Desde a recepção dos bezerros até o boi gordo, bem estar animal é nossa obstinação. A constante capacitação dos especialistas em manejo, as parcerias com as melhores empresas mundiais em nutrição e saúde animal, credenciam a FRIBAL a postos elevados da pecuária nacional.',
        ],
      },
      {
        id: 3,
        image1: require('../assets/pecuaria/pecuaria-5.jpg'),
        image2: require('../assets/pecuaria/pecuaria-6.jpg'),
        title: 'Gestão responsável',
        descriptions: [
          'Em todas as fases do processo; recria, confinamento de engorda e agricultura, o sistemático controle de todos os indicadores, aliado às mais modernas técnicas e sistemas de gestão garantem a sustentabilidade do negócio, a previsibilidade dos resultados e a qualidade do produto final. ',
        ],
      },
    ],
    gallery: [
      {
        id: '1',
        image: require('../assets/pecuaria/pecuaria-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/pecuaria/pecuaria-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/pecuaria/pecuaria-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/pecuaria/pecuaria-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/pecuaria/pecuaria-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/pecuaria/pecuaria-6.jpg'),
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/placeholder-tour.jpg'),
    },
  },
  industria: {
    meta: {
      prev: '/pecuaria',
      next: '/distribuicao-e-exportacao',
      title: 'Indústria',
      position: '02',
      360: '/360/industria/index.html',
    },
    home: {
      link: "/industria",
      title: "Indústria",
      about: "Cuidados, higiene e proteção para garantir a qualidade do produto",
      background: {
        0: require('../assets/industria/industria-banner.jpg'),
        566: require('../assets/industria/industria-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/industria/industria-subheader.jpg'),
      title: 'Tecnologia de ponta ao longo de todo o processo e Procedimentos rigorosamente controlados',
      description: 'Contamos com duas modernas plantas frigoríficas no Maranhão que processam mais 87 milhões de quilos de carne por ano. Com tecnologia de ponta, produzimos seguindo padrões internacionais de qualidade para garantir a segurança alimentar e preservar os nutrientes próprios da carne bovina.',
    },
    sections: [
      {
        id: 4,
        image1: require('../assets/industria/industria-1.jpg'),
        image2: require('../assets/industria/industria-2.jpg'),
        title: 'Plantas eficientes',
        descriptions: [
          'Investimos continuamente na modernização de nossas plantas buscando a melhoria dos processos para: racionalização no uso de recursos, melhoria da qualidade do produto final e aumento da segurança alimentar para os consumidores de nossos produtos.',
        ],
      },
      {
        id: 5,
        image1: require('../assets/industria/industria-3.jpg'),
        image2: require('../assets/industria/industria-4.jpg'),
        title: 'Capacidade produtiva',
        descriptions: [
          'Duas plantas frigoríficas localizadas no estado do Maranhão com capacidade de abate de 320.000 cabeças/ano, respondem por 65% da capacidade de abate do estado, em estabelecimentos sob certificação federal (SIF).',
          {
            id: 1,
            img: require('../assets/selos.png')
          },
        ],
      },
      {
        id: 6,
        image1: require('../assets/industria/industria-5.jpg'),
        image2: require('../assets/industria/industria-6.jpg'),
        title: 'Qualidade assegurada',
        image: require('../assets/selos.png'),
        descriptions: [
          'Monitoramento socioambiental da compra de gado, treinamento contínuo de nossos especialistas de operação da indústria, eficiente controle dos processos e elevada exigência de qualidade, garantem segurança alimentar e responsabilidade social aos produtos FRIBAL. Toda essa dedicação garante lugar de destaque junto a nossos clientes e parceiros, no Brasil e no mundo.',
        ],
      },
    ],
    gallery: [
      {
        id: '1',
        image: require('../assets/industria/industria-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/industria/industria-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/industria/industria-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/industria/industria-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/industria/industria-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/industria/industria-6.jpg'),
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/industria/industria-tour.jpg'),
    },
  },
  distribuicao: {
    meta: {
      prev: '/industria',
      next: '/canais',
      title: 'Distribuição',
      position: '03',
      360: '/360/distribuicao/index.html',
    },
    home: {
      link: "/distribuicao",
      title: "Distribuição",
      about: "Logística integrada de ponta a ponta",
      background: {
        0: require('../assets/distribuicao/distribuicao-banner.jpg'),
        566: require('../assets/distribuicao/distribuicao-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/distribuicao/distribuicao-subheader.jpg'),
      title: 'Produzimos e levamos com toda a segurança, assim chegamos mais longe',
      description: 'De ponta a ponta, todo produto é pesado e identificado e transportado pela própria FRIBAL. Nossa estrutura logística garante que o produto da indústria chegue às lojas em um curto espaço de tempo.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/distribuicao/distribuicao-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/distribuicao/distribuicao-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/distribuicao/distribuicao-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/distribuicao/distribuicao-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/distribuicao/distribuicao-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/distribuicao/distribuicao-6.jpg'),
      },
    ],
    sections: [
      {
        id: 7,
        image1: require('../assets/distribuicao/distribuicao-1.jpg'),
        image2: require('../assets/distribuicao/distribuicao-2.jpg'),
        title: 'Frota própria',
        descriptions: [
        'Moderna frota de veículos próprios garante a qualidade do produto e a eficiência logística de ponta a ponta, importantes diferenciais da FRIBAL junto a seus clientes e parceiros.',
        ],
      },
      {
        id: 8,
        image1: require('../assets/distribuicao/distribuicao-3.jpg'),
        image2: require('../assets/distribuicao/distribuicao-4.jpg'),
        title: 'Rastreabilidade e Controle',
        descriptions: [
        '100% dos veículos que transportam os produtos FRIBAL são rastreados geograficamente e a temperatura de suas carretas frigoríficas controladas online assegurando o compromisso com a eficiência da logística e a garantia da qualidade.',
        ],
      },
      {
        id: 9,
        image1: require('../assets/distribuicao/distribuicao-5.jpg'),
        image2: require('../assets/distribuicao/distribuicao-6.jpg'),
        title: 'Exportação a FRIBAL',
        descriptions: [
        'A FRIBAL é a maior exportadora de carne bovina da região nordeste, sozinha é responsável por 80% da carne exportada pelo estado do Maranhão. Hoje alcançando países com elevados padrões de qualidade e trabalhando continuamente em busca da expansão destes horizontes, sempre com a chancela do Ministério de Agricultura, Pecuária e Abastecimento.',
        ],
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/distribuicao/distribuicao-tour.jpg'),
    },
  },
  canais: {
    meta: {
      prev: '/distribuicao',
      next: '/marcas',
      title: 'Canais de Venda',
      position: '04',
      360: [{ label: 'Entre em contato', link: '/fale-conosco?dp=Exportação', target: '_self' }],
    },
    home: {
      link: "/canais",
      title: "Canais de venda",
      about: "Comercializamos nossos produtos para todo Mundo",
      background: {
        0: require('../assets/canais/canais-banner.jpg'),
        566: require('../assets/canais/canais-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/canais/canais-subheader.jpg'),
      title: 'A FRIBAL comercializa para todo mundo',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint inventore tenetur harum blanditiis pariatur molestiae a amet, laboriosam culpa facilis.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/canais/canais-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/canais/canais-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/canais/canais-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/canais/canais-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/canais/canais-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/canais/canais-6.jpg'),
      },
      {
        id: '7',
        image: require('../assets/canais/canais-7.jpg'),
      },
      {
        id: '8',
        image: require('../assets/canais/canais-8.jpg'),
      },
    ],
    sections: [
      {
        id: 10,
        image1: require('../assets/canais/canais-1.jpg'),
        image2: require('../assets/canais/canais-2.jpg'),
        title: 'Vendas Internacionais',
        descriptions: [
          'Sempre em busca da expansão para novos mercados, a FRIBAL atende a exigentes requisitos sanitários de vários países, com protocolos internacionais garantidos pelo Serviço de Inspeção Federal Brasileiro, SIF. Com processos personalizados de logística, nossos clientes têm a garantia que os produtos FRIBAL chegam com total qualidade.',
        ],
      },
      {
        id: 11,
        image1: require('../assets/canais/canais-3.jpg'),
        image2: require('../assets/canais/canais-4.jpg'),
        title: 'Vendas Nacionais',
        descriptions: [
         'Nossos produtos chegam em todos os cantos do Brasil. Competência comercial, produtos de qualidade e logística própria, são alguns dos ingredientes de parcerias de sucesso. Entre em contato com nosso departamento de vendas e descubra como é fácil ter FRIBAL em sua cidade.  ',
        ],
      },
      {
        id: 12,
        image1: require('../assets/canais/canais-5.jpg'),
        image2: require('../assets/canais/canais-6.jpg'),
        title: 'Vendas Regionais',
        descriptions: [
        'Para atender com exclusividade os estados do Maranhão e Ceará, nossos canais regionais estão à disposição. Atendemos a estes estados diretamente de nossos Centros de Distribuição localizados em São Luis/MA e Fortaleza/CE e com frota própria, oferecendo serviço com qualidade e eficiência.',
        ],
      },
      {
        id: 13,
        image1: require('../assets/canais/canais-7.jpg'),
        image2: require('../assets/canais/canais-8.jpg'),
        title: { href: 'http://www.fribaldelivery.com.br', text: [
            'Food Service',
            <a href="http://www.fribaldelivery.com.br" target="_blank" rel="noopener noreferrer" class="Button Button--primary-outline Section__action">Delivery Maranhão</a>,
          ]
        },
        descriptions: [
        'Nos estados do Maranhão e do Ceará criamos uma a área para Food Service especialmente voltada ao atendimento de restaurantes, lanchonetes, cozinhas industriais  e outros negócios que dependam de serviços, produtos e logística customizada. Nossos especialistas estão a postos para encontrar as melhores soluções para as necessidades deste ramo.',
        ],
      },
    ],
    callToAction: null,
  },
  marcas: {
    meta: {
      prev: '/canais',
      next: '/varejo',
      title: 'Marcas',
      position: '05',
      360: null,
    },
    home: {
      link: "/marcas",
      title: "Marcas",
      about: "Cortes especialmente selecionados",
      background: {
        0: require('../assets/produtos/produtos-banner.jpg'),
        566: require('../assets/produtos/produtos-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/produtos/produtos-subheader.jpg'),
      title: 'Produtos para cada momento de sua vida!',
      description: 'Além das tradicionais carnes, aqui você encontra mais de 6 mil itens que vão do tempero para aquela receita especial até o vinho que servirá de acompanhamento.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/produtos/diaadia-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/produtos/diaadia-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/produtos/grill-1.jpg'),
      },
      {
        id: '4',
        image: require('../assets/produtos/grill-2.jpg'),
      },
      {
        id: '5',
        image: require('../assets/produtos/selection-1.jpg'),
      },
      {
        id: '6',
        image: require('../assets/produtos/selection-2.jpg'),
      },
    ],
    sections: [
      {
        id: 14,
        image1: require('../assets/produtos/diaadia-1.jpg'),
        image2: require('../assets/produtos/diaadia-2.jpg'),
        title: 'FRIBAL Dia a Dia',
        descriptions: [
        'Abrangente linha de produtos com cortes do dianteiro, traseiro e miúdos, apresentados em embalagem a vácuo, congelados e resfriados para atender a todas as necessidades do dia a dia da cozinha doméstica e profissional.',
        ],
      },
      {
        id: 15,
        image1: require('../assets/produtos/grill-1.jpg'),
        image2: require('../assets/produtos/grill-2.jpg'),
        title: 'FRIBAL Grill',
        descriptions: [
        'Produtos criteriosamente selecionados para atender os amantes de um bom churrasco e da culinária gourmet. Os cuidados especiais na manipulação do produto garantem uma qualidade superior.',
        ],
      },
      {
        id: 16,
        image1: require('../assets/produtos/selection-1.jpg'),
        image2: require('../assets/produtos/selection-2.jpg'),
        title: 'FRIBAL Selection',
        descriptions: [
        'Dos melhores e mais jovens rebanhos da pecuária maranhense nasce a linha Selection. Animais precoces, abaixo de 30 meses, manejados e engordados em nossas propriedades com as mais modernas técnicas nutricionais e zootécnicas. Produtos cuidadosamente selecionados e manipulados em nossos frigoríficos garantem os diferenciais desta linha.',
        ],
      },
      {
        id: 21,
        image1: require('../assets/produtos/dry-aged-1.jpg'),
        image2: require('../assets/produtos/dry-aged-2.jpg'),
        title: 'FRIBAL DRY AGED',
        descriptions: [
        'Produto cuidadosamente selecionado com alta suculência, equilíbrio e maciez que surpreendem desde o primeiro corte. O processo é capaz de produzir o 5° sabor, também chamado de Umami, que significa ‘‘delicioso’’. Seu efeito é fundamental para equilibrar o gosto e harmonizar o sabor total do corte.',
        ],
      },
    ],
    callToAction: null,
  },
  varejo: {
    meta: {
      prev: '/distribuicao',
      next: null,
      title: 'Varejo',
      position: '06',
      360: [
        { label: 'Empório', link: '/360/emporio/index.html' },
        { label: 'FRIBAL', link: '/360/sao-francisco/index.html' },
      ],
    },
    home: {
      link: "/varejo",
      title: "Varejo",
      about: "Sempre perto sempre à mesa",
      background: {
        0: require('../assets/varejo/varejo-banner.jpg'),
        566: require('../assets/varejo/varejo-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/varejo/varejo-subheader.jpg'),
      title: 'Todo dia perto de você',
      description: 'Maior rede de casas de carne do Nordeste, as lojas FRIBAL são pioneiras no conceito de boutique de carne, com mais de 50 lojas no estado oferecendo atendimento diferenciado, produtos de qualidade obedecendo à todas as normas de higiene e segurança alimentar.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/varejo/varejo-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/varejo/varejo-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/varejo/varejo-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/varejo/varejo-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/varejo/varejo-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/varejo/varejo-6.jpg'),
      },
    ],
    sections: [
      {
        id: 17,
        image1: require('../assets/varejo/varejo-1.jpg'),
        image2: require('../assets/varejo/varejo-2.jpg'),
        title: { href: '/lojas?estado=MA', text: [
            'Casas de Carne FRIBAL',
            <Link key="lojas" to="/lojas?estado=MA" className="Button Button--primary-outline Section__action">+ Lojas</Link>,
          ]
        },
        descriptions: [
        'No Nordeste, nossas lojas são pioneiras com o conceito de boutique de carne, com mais de 50 lojas, sempre oferecendo atendimento diferenciado, produtos de qualidade; obedecendo à todas as normas de higiene e segurança alimentar. Nossos especialistas do varejo garantindo que nossos clientes tenham um produto de qualidade à mesa, finalizando o ciclo, FRIBAL “Do Pasto Ao Prato”.',
        ],
      },
      {
        id: 18,
        image1: require('../assets/varejo/varejo-5.jpg'),
        image2: require('../assets/varejo/varejo-6.jpg'),
        title: { href: 'http://emporiofribal.com.br/', text: [
            'Empório FRIBAL',
            <a href="http://emporiofribal.com.br/" target="_blank" rel="noopener noreferrer" class="Button Button--primary-outline Section__action">Site do Empório</a>,
          ]
        },
        descriptions: [
          'Da paixão que temos em oferecer os melhores produtos, a maior variedade e mais conforto aos nossos clientes nasce o conceito FRIBAL EMPÓRIO GOURMET, onde num ambiente único oferecemos as melhores e mais completas experiências gastronômicas com o lema “Aqui a vida é tudo de bom”.',
        ]
      },
      {
        id: 19,
        image1: require('../assets/varejo/varejo-3.jpg'),
        image2: require('../assets/varejo/varejo-4.jpg'),
        title: { href: '/lojas?estado=CE', text: [
            'Shop in Shop',
            <Link key="lojas" to="/lojas?estado=CE" className="Button Button--primary-outline Section__action">+ Lojas</Link>,
          ]
        },
        descriptions: [
          'Com presença marcante no estado do Ceará com mais de 120 lojas, em um exclusivo modelo de parceria com as principais redes de supermercados do estado,  onde respondemos por todo o setor de carnes, garantindo o produto e o atendimento com a qualidade FRIBAL.',
        ],
      },
      {
        id: 20,
        image1: require('../assets/varejo/mercearia-1.jpg'),
        image2: require('../assets/varejo/mercearia-2.jpg'),
        title: { href: '/lojas', text: [
            'Mercearia FRIBAL',
            <Link key="lojas" to="/lojas" className="Button Button--primary-outline Section__action">+ Lojas</Link>,
          ]
        },
        descriptions: [
          'A praticidade e o atendimento personalizado são diferenciais de peso crescente na decisão de compra dos consumidores. Cada vez mais modelos inovadores de negócio têm sido adotados pelo varejo a busca desta entrega. Exatamente nesta direção a FRIBAL lança um novo conceito de loja: Mercearia FRIBAL, uma bandeira que busca a tradução perfeita do varejo de proximidade.',
        ],
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/varejo/varejo-tour.jpg'),
    },
  },
};
