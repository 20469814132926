import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import useMenu from '../hooks/useMenu';
import Header from '../components/Header';
import VideoModal from '../components/VideoModal';

import Home from './Home';
import Content from './Content';
import About from './About';
import Contact from './Contact';
import Stores from './Stores';

import content from '../content/pastoAoPrato';
import Splashscreen from './Splashscreen';

export default () => {
  const [isOpen, setOpen] = useMenu();
  const [isVideoOpen, setVideoOpen] = useState(false);

  return (
    <Router>
      <>
        <Switch>
          <Route exact path="/">
            <Header size="normal" isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Home />
          </Route>

          {/* Do pasto ao prato */}
          <Route exact path="/pecuaria">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Content content={content.pecuaria} className="Content--pecuaria" />
          </Route>
          <Route exact path="/industria">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Content content={content.industria} className="Content--industria" />
          </Route>
          <Route exact path="/distribuicao">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Content content={content.distribuicao} className="Content--distribuicao" />
          </Route>
          <Route exact path="/canais">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Content content={content.canais} className="Content--canais" />
          </Route>
          <Route exact path="/marcas">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Content content={content.marcas} className="Content--produtos" />
          </Route>
          <Route exact path="/varejo">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Content content={content.varejo} className="Content--varejo" />
          </Route>

          {/* Lojas */}
          <Route exact path="/lojas">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Stores />
          </Route>

          {/* Outras páginas */}
          <Route exact path="/sobre-nos">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <About />
          </Route>
          <Route exact path="/fale-conosco">
            <Header isOpen={isOpen} setMenuOpen={setOpen} setVideoOpen={setVideoOpen}/>
            <Contact />
          </Route>

        </Switch>
        <Route exact path='/emporio' component={() => { 
            window.location.href = 'http://emporiofribal.com.br'; 
            return null;
        }}/>

        <VideoModal videoId="aLxlLO0enhA" isOpen={isVideoOpen} setOpen={setVideoOpen} />
        <Splashscreen />
      </>
    </Router>
  );
};
