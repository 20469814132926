import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavigationTrigger from './Trigger';
import VideoTrigger from '../VideoTrigger';
import Logo from '../Logo';
import './style.sass';

const Navigation = ({ isOpen, setOpen, setVideoOpen }) => {
  const classNames = ['Navigation'];

  if (isOpen) {
    classNames.push('Navigation--open');
  }

  const clickSideEffect = useCallback(() => {
    setOpen(false);
    const content = document.querySelector('.Content');
    const hasContent = !!content;

    if (hasContent) {
      content.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [setOpen]);

  return (
    <nav className={classNames.join(' ')}>
      <Logo Element="span" size="nav"/>
      <ul className="Navigation__list">
        <li className="Navigation__item">
          <Link className="Navigation__link" onClick={clickSideEffect} to="/">Início</Link>
        </li>
        <li className="Navigation__item">
          <VideoTrigger className="Navigation__link" theme="navigation" onClick={clickSideEffect} setOpen={setVideoOpen}>Vídeo Institucional</VideoTrigger>
        </li>
        <li className="Navigation__item">
          <Link className="Navigation__link" onClick={clickSideEffect} to="/lojas">Lojas</Link>
        </li>
        <li className="Navigation__item">
          <Link className="Navigation__link" onClick={clickSideEffect} to="/fale-conosco">Fale conosco</Link>
        </li>
        <li className="Navigation__item">
          <a className="Navigation__link" target="_blank" rel="noopener noreferrer" href="http://fribal.homeip.net:8081/RM/RM/Rhu-BancoTalentos/#/RM/Rhu-BancoTalentos/home" >Trabalhe conosco</a>
        </li>
      </ul>

      <div className="Navigation__trigger-container">
        <NavigationTrigger mode="close" isOpen={isOpen} setOpen={setOpen}>Fechar</NavigationTrigger>
      </div>

    </nav>
  );
};

Navigation.defaultProps = {
  isOpen: false,
  setOpen: () => [],
};

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
};

Navigation.Trigger = NavigationTrigger;

export default Navigation;
